<template>
  <div class="partner-page">
    <h1 class="text-dark-blue">Soon...</h1>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.partner-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify;
}
</style>