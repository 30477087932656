<template>
  <div>
    <!-- <Navbar /> -->
    <PartnerShip />
    <!-- <Footer /> -->
  </div>
</template>

<script>
import PartnerShip from "../components/information/PartnerShip.vue";
import Footer from "../components/shared/Footer.vue";
import Navbar from "../components/shared/Navbar.vue";

export default {
  components: {
    PartnerShip,
    Navbar,
    Footer,
  },
  created() {
    const lang = this.$i18n.locale === "tr" ? "" : this.$i18n.locale;
    location.href = `https://flinktax.de/${lang}/partner`;
  },
};
</script>

<style></style>
